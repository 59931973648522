import "url-search-params-polyfill";
import useSearchParams from "@src/utils/useSearchParams";
import { useRedirectHandler } from "@src/utils/useRedirectHandler";

function SchoolApproval() {
  const [schoolId] = useSearchParams("s");

  useRedirectHandler(() => {
    if (schoolId) {
      return `https://teach.classdojo.com/#/onboarding/schools/${schoolId}/directory/teachers`;
    } else {
      return "https://teach.classdojo.com/#/schools/directory";
    }
  });

  return null;
}

export default SchoolApproval;
